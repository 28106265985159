import themeScreens from "../../generated/tailwindScreens";

// Convert the tailwind screens object into array
const breakpointsFromTailwindCss = () => {
    return Object.entries(themeScreens).map((breakpoint) => {
        const key = breakpoint[0];
        const val = Number(breakpoint[1].replace(/px/g, ""));
        return { key, val };
    });
};

const breakpointIs = function (key) {
    const breakpoints = breakpointsFromTailwindCss();
    const windowWidth = window.innerWidth;
    const pointVal = breakpoints.find((point) => point.key == key);

    if (!pointVal) {
        return false;
    }

    if (windowWidth >= pointVal.val) {
        return true;
    }
    return false;
}

export default breakpointIs;