import getLinkText from "../helpers/getLinkText";
import { trackEvent } from "../trackEvent";

/**
 * Listen to dispatch from UIKit and push the event
 */

window.addEventListener("tab", ({ detail }) => {
  const {event, trigger, index} = detail;
  
  trackEvent({
    'event': 'cmp_event',
    'event_type': 'tab',
    'title': getLinkText({target: trigger}),
    'position': index + 1
  });
});