/**
 * Takes the target element and returns the innerText 
 * When no text is found it will look for an image and return the alt text
 * When still no text it returns the link
 */

export default ({ target }) => {
    const ariaLabel = target.ariaLabel;
    if (ariaLabel) {
      return ariaLabel;
    }
  
    let actionButtonText = target.innerText;
    const image = target.querySelector("img");
    if (image && actionButtonText == "") {
      const imageAlt = image.getAttribute("alt");
      if (imageAlt != "") {
        return imageAlt;
      }
    }
  
    return actionButtonText ? actionButtonText : target.outerHTML;
  }