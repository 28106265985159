/**
 * OneTrustStore
 * This file contains the logic for managing OneTrust consent state within the Alpine.
 * It handles the state of OneTrust consent (accepted or rejected).
 */

Alpine.store("oneTrust", {
  consents: Alpine.$persist([]),
  init() {
    // EventListener for onetrust when consent changed
    window.addEventListener("consent.onetrust", ({ detail }) => {
      this.consents = detail;
    });
  },
  get hasCookieConsent() {
    // Return true if onetrust doesn't exist on the page
    if (!document.querySelector("#onetrust-consent-sdk")) {
      return true;
    }
    /*
       OneTrust Codes: ['C0001', 'C0002', 'C0003', 'C0005', 'C0004']
       Code to check to know if has cookie consent: ['C0002', 'C0004']
    */
    const oneTrustCookieConsentAprrovalCodes = ["C0002", "C0004"];

    return oneTrustCookieConsentAprrovalCodes.every((consent) =>
      this.consents.includes(consent)
    );
  },
  // Toggle OneTrust Info Display
  toggleInfoDisplay() {
    window.OneTrust?.ToggleInfoDisplay();
    // close oneTrust overlay
    const closeBtn = document.getElementById("close-pc-btn-handler");
    closeBtn?.addEventListener("click", (e) => {
      e.target.removeEventListener("click", () => {});
      window.OneTrust?.Close();
    });
  },
});