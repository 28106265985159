/**
 * Takes the target element and returns the innerText 
 * When no text is found it will look for an image and return the alt text
 * When still no text it returns the link
 */

export default ({ target, link }) => {

  const ariaLabel = target.ariaLabel;
  if (ariaLabel) {
    return ariaLabel;
  }

  let linkText = target.innerText;
  const image = target.querySelector("img");
  if (image && linkText == "") {
    const imageAlt = image.getAttribute("alt");
    if (imageAlt != "") {
      linkText = imageAlt;
    }
  }

  return linkText ? linkText : link;
}