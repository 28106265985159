const tables = document.querySelectorAll('.rich-text table');

const wrapTable = el => {
  const wrappingElement = document.createElement('div');
  wrappingElement.setAttribute('role', 'region');
  el.replaceWith(wrappingElement);
  wrappingElement.classList.add('table-wrap');
  wrappingElement.appendChild(el);
}

tables.forEach(el => wrapTable(el));