import { trackEvent } from "../../../trackEvent";
import getClosestAttributeWithValue from "../../../helpers/getClosestAttributeWithValue";

/**
 * This module is used for tracking interactions with elements like the following:
 *
 * <a class="button eventDetail__button secondary " href="#watchRecap" x-show="eventIsStarted" data-gtm-type="watch_recap_link" data-name="Example Event" data-event-type="Webinar" data-event-category="Education" data-date="2023-01-01">
 *    Watch Recap
 * </a>
 *
 * The `test` function checks if the clicked element or its closest ancestor has the 'data-gtm-type' attribute set to 'watch_recap_link'.
 * The `push` function extracts additional data attributes (name, event type, event category, and date) from the element and uses them to track the event.
 */

const eventName = target => target.getAttribute('data-name');
const eventType = target => target.getAttribute('data-event-type');
const eventCategory = target => target.getAttribute('data-event-category');
const eventDate = target => target.getAttribute('data-date');

const test = ({ target }) => getClosestAttributeWithValue({ target: target, attribute: 'data-gtm-type', value: 'watch_recap_link' });

const push = ({ target }) => {
    trackEvent({
        'event': "webinar_recap",
        'interaction_type': "calendar",
        'name': eventName(target),
        'event_type': eventType(target),
        'event_category': eventCategory(target),
        'date': eventDate(target),
    });
}

export default {
    name: "watch_recap_link",
    test,
    push
};