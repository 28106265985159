import getLinkText from "../../../helpers/getLinkText";
import getSection from "../../../helpers/getSection";
import { trackEvent } from "../../../trackEvent";

/**
 * Push article link clicks to datalayer when we find data-gtm-type of article_card
 * This assumes some basic markup like the following based on UIKit
 * 
 * <div data-gtm-section="articlesList">
 *  <a href="" data-gtm-type="article_card">
 *     <h3 class="card__heading">Article heading</h3>
 *  </a>
 * </div>
 */


const test = ({ target }) => target.getAttribute("data-gtm-type") == "article_card" || target.closest('[data-gtm-type="article_card"]');

const getArticleTitle = ({ target, link }) => {
  const heading = target.querySelector(".card__heading");
  return getLinkText({ link, target: heading });
};

const getArticleIndex = ({ target }) => target.getAttribute("data-gtm-index");

const push = ({ link, target }) => {
  trackEvent({
    'event': 'article_click',
    'interaction_type': 'content',
    'section': getSection(target),
    'component_type': 'card',
    'element_type': 'article',
    'name': getArticleTitle({ link, target }),
    'position': getArticleIndex({ target })
  });
}

export default {
  name: "article_card",
  test,
  push
};