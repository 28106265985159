import getActionButtonText from "../../../helpers/getActionButtonText";
import getSubsection from "../../../helpers/getSubsection";
import { trackEvent } from "../../../trackEvent";

/**
 * Push any header links to datalayer when they are in the .header tag
 * Basic DOM markup assumed.
 * 
 * <div class="header">
 *  <div data-gtm-subsection="social">
 *   <button data-gtm-click>Facebook</a>
 *  </div>
 * </div>
 */

const test = ({ target }) => target.closest("footer");

const push = ({ target }) => {
  trackEvent({
    event: "navigation",
    interaction_type: "navigation",
    section: "footer",
    sub_section: getSubsection(target),
    element: getActionButtonText({ target })
  });
}

export default {
  name: "footer",
  test,
  push
};