/**
 * 
 * @param {DOM Element} element 
 * @returns the value of the closest element with the attribute of data-gtm-subsection if found. Otherwise empty string.
 * 
 * <div data-gtm-subsection="articles">
 *  <a class="articleLink" href="">Article link</a>
 * </div>
 * 
 * getSectionElement(document.querySelector(".articleLink")) => "articles"
 */

const getSubSectionElement = (element) => element.closest("[data-gtm-subsection]");

export default (element) => {
  const el = getSubSectionElement(element);
  return el ? el.dataset.gtmSubsection : "";
};