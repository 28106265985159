import { trackEvent } from "../../../trackEvent";

/**
 * Push any download links to datalayer based on the below regex
 */


const test = ({ link }) => /^(\/(\.resources|\.imaging|dam|local\/dam|local\/\.resources|local\/\.imaging|author\/dam|author\/\.resources|author\/\.imaging)\/([\w\\/\-\\:\s\\%\\&\\#\\@]*).+)$/gi.test(link);

const push = ({ link, target, e }) => {
  e.preventDefault();
  window.open(link, target.target);

  const fileName = link.split("/").pop();
  const fileFormat = fileName.split(".").pop();

  trackEvent({
    event: "download",
    name: fileName,
    document_format: fileFormat,
    interaction_type: "download"
  });
}

export default {
  name: "download",
  test,
  push
};