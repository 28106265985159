import externalLink from "./linkTypes/externalLink";
import downloadLink from "./linkTypes/downloadLink";
import footerLink from "./linkTypes/footerLink";
import headerLink from "./linkTypes/headerLink";
import internalLink from "./linkTypes/internalLink";
import articleCardLink from "./linkTypes/articleCardLink";
import watchRecapLink from "./linkTypes/watchRecapLink";
import featuredResourcesLink from "./linkTypes/featuredResourcesLink";
import featuredPharmaProductLink from "./linkTypes/featuredPharmaProductLink";

/**
 * Listen to all link clicks on the site and decide if we should pass into GTM
 */


const origin = window.location.origin;
const isInternal = new RegExp("^" + origin, "g");
const getLinkTarget = target => target.localName == "a" ? target : target.closest("a");

/**
 * This is a list of modules to test what kind of link this is and what event 
 * should be sent to GTM based on the dataLayer rules
 * These are performed in the order they are shown in the list and will only fire
 * a single event.
 * 
 * Each module exports an object with the following shape
 * 
 * {
 *   test: function(){}, Takes the ops object and tests the link or target of the click event to evaluate if this should trigger the GTM event. Return Boolean
 *   push: function(){}, Does the actual work of calling trackEvent() and creating the object shape to push
 *   name: String used to debug :) 
 * }
 */
const linkTestTypes = [articleCardLink, watchRecapLink, featuredPharmaProductLink, featuredResourcesLink, downloadLink, headerLink, footerLink, externalLink, internalLink];

const trackLink = (ops) => {
  for (i = 0; i < linkTestTypes.length; i++) {
    const test = linkTestTypes[i].test;

    if (test(ops)) {
      const push = linkTestTypes[i].push;
      push(ops);
      break;
    }
  }
};

/**
 * Capture all clicks on the body and ensure the clicked item
 * has the href value with a value
 */
document.body.addEventListener("click", (e) => {
  const target = getLinkTarget(e.target);

  if (target) {
    const link = target.getAttribute("href");

    if (link) {
      // The `target` here should be the HTML element (anchor tag).
      trackLink({ link, target, e, isInternal });
    }
  }
});