import getSubsection from "../../../helpers/getSubsection";
import getLinkText from "../../../helpers/getLinkText";
import { trackEvent } from "../../../trackEvent";

/**
 * Push any footer links to datalayer when they are in the footer tag
 * Basic DOM markup assumed.
 * 
 * <footer>
 *  <div data-gtm-subsection="social">
 *   <a href="">Facebook</a>
 *  </div>
 * </footer>
 */

const test = ({ target }) => target.closest("footer");

const push = ({ link, target }) => {
  trackEvent({
    event: "navigation",
    interaction_type: "navigation",
    section: "footer",
    sub_section: getSubsection(target),
    element: getLinkText({ link, target })
  });
}

export default {
  name: "footer",
  test,
  push
};