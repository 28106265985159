import getLinkText from "../helpers/getLinkText";
import { trackEvent } from "../trackEvent";

/**
 * Listen to dispatch from UIKit and push the event
 */

window.addEventListener("accordion", ({ detail }) => {
  const {event, title, el} = detail;
  
  trackEvent({
    "event": "cmp_event",
    "event_type": "accordion",
    "action": event,
    "title": getLinkText({target: title})
  });
});