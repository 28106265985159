import Alpine from "alpinejs";

Alpine.magic("loadScript", () => (src, callback = () => {}) => {
    if (!document.querySelector("script[src='" + src + "']")) {
        const script = document.createElement("script");
        script.src = src;
        script.onload = callback;
        document.head.appendChild(script);
    } else {
        callback();
    }
});
