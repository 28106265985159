import isEditMode from "../isEditMode"

const DEBUG = true;

/**
 * 
 * @param {object} obj 
 * 
 * Object takes the shape of what we should pass into GTM
 * 
 * trackEvent({
 *  event: "outbound_link",
 *  url: "http://www.google.com",
 *  interaction_type: "outbound_link"
 * })
 */

export const trackEvent = (obj) => {
  if (DEBUG || localStorage.getItem("track_debug")) {
    console.group(`Track event ${obj.event}`);
    console.table(obj);
    console.groupEnd();
  }

  // Use try to prevent js issues with ad blockers
  try {
    if (window.dataLayer && !isEditMode()) { // Don't run in edit mode
      window.dataLayer.push(obj);
    }
  } catch (error) {
    console.warn(error);
  }
};