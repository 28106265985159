import getLinkText from "./getLinkText";

/**
 * Retrieves the text of the heading element inside a card.
 * 
 * @param {DOM Element} element The DOM element that is within the card component.
 * @returns {string} The text of the card's heading if found, otherwise an empty string.
 * 
 * Example usage:
 * <div data-gtm-section="card">
 *      <h3 class="card__heading">Title heading</h3>
 *      <a href="">Link Text</a>
 * </div>
 * 
 * getCardTitle(document.querySelector("[data-gtm-section='card']")) => "Title heading"
 */
export default function getCardTitle({ target, link }) {
    const heading = target.querySelector(".card__heading");
    return heading ? getLinkText({ link, target: heading }) : "";
};
