import getLinkText from "../../../helpers/getLinkText";
import getCardTitle from "../../../helpers/getCardTitle";
import getCardIndex from "../../../helpers/getCardIndex";
import getSection from "../../../helpers/getSection";
import getClosestAttributeWithValue from "../../../helpers/getClosestAttributeWithValue";
import { trackEvent } from "../../../trackEvent";

/**
 * Push link clicks to datalayer when we find data-gtm-type of pharma_card
 * This assumes some basic markup like the following based on UIKit
 * 
 * <div data-gtm-section="pharma_card">
 *      <h3 class="card__heading">Title heading</h3>
 * 
 *      <a href="">Link Text</a>
 * </div>
 */

const test = ({ target }) => getClosestAttributeWithValue({ target: target, attribute: 'data-gtm-type', value: 'pharma_card' });

const push = ({ link, target }) => {
    const pharmaCard = getClosestAttributeWithValue({ target, attribute: 'data-gtm-type', value: 'pharma_card' });

    trackEvent({
        'event': 'cmp_event',
        'event_type': 'card',
        'title': getCardTitle({ target: pharmaCard, link }),
        'position': getCardIndex({ target: pharmaCard }),
    });
}

export default {
    name: "pharma_card",
    test,
    push
};