/**
 * Retrieves the value of the data-gtm-index attribute from the target element or the closest ancestor with that attribute.
 * 
 * @param {DOM Element} target The element from which to start the search for the data-gtm-index attribute.
 * @returns {string} The value of the target element's or closest ancestor's data-gtm-index attribute if found, otherwise an empty string.
 * 
 * Example usage:
 * <div data-gtm-index="1" data-gtm-type="element">
 *   [#nested]
 * </div>
 * 
 * getCardIndex(document.querySelector("[data-gtm-type='element']")) => "1"
 */
export default function getCardIndex({ target }) {
    // Check if the target itself has the 'data-gtm-index' attribute, otherwise look for the closest ancestor with it
    return target.getAttribute("data-gtm-index") || (target.closest("[data-gtm-index]")?.getAttribute("data-gtm-index")) || "";
}
