import getLinkText from "../../../helpers/getLinkText";
import { trackEvent } from "../../../trackEvent";

/**
 * Push any internal links to datalayer when they match the below regex and do not match link testers
 * Note: This should be last in the tester list
 */

const test = ({ link, isInternal }) => /^(\/([\w\\/\-\\:\s\\%\\&\\#\\@\\?\\=]*)(.html|htm)*)$/gi.test(link) || isInternal.test(link);

const push = ({ link, target }) => {
  const linkText = getLinkText({target, link});

  trackEvent({
    event: "cmp_event",
    event_type: "cta",
    title: linkText,
    context: "others",
    eventCategory: "navigation",
    eventAction: linkText
  });
};

export default {
  name: "internal",
  test,
  push
};