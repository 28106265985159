import { trackEvent } from "../../../trackEvent";

/**
 * Push any external links to datalayer based on the below regex
 */

const test = ({ link, isInternal }) => /^((http|https):\/\/)?(([\w.-]*)\.([\w]))(\/[^\\?$]*)?/g.test(link) && !isInternal.test(link);

const push = ({ link }) => trackEvent({
  event: "outbound_link",
  url: link,
  interaction_type: "outbound_link"
});

export default {
  name: "external",
  test,
  push
};