/**
**  sectionId: Makes sure to initialise the navigation link with the right sectionId
** if a section is already available in the viewport
**/

Alpine.store('sectionId', {
    visibleSections: [],
    getTopVisible(anchorList = []) {
        // look for the first visible section in the list of available anchors
        return anchorList.find((anchor) => this.visibleSections.includes(anchor)) || this.visibleSections[0] || "";
    },
    addSection(sectionId) {
        if (!this.visibleSections.includes(sectionId)) {
            this.visibleSections.push(sectionId);
        }
    },
    removeSection(sectionId) {
        this.visibleSections = this.visibleSections.filter((id) => id !== sectionId);
    }
});
