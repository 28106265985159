/**
 * Retrieves the target element itself or the closest ancestor element that has the specified attribute with a specific value.
 * 
 * @param {Object} param An object containing the target, attribute, and value properties.
 * @param {Element} param.target The DOM element to check for the attribute.
 * @param {string} param.attribute The name of the attribute to look for.
 * @param {string} param.value The value of the attribute to match.
 * @returns {Element|null} The target element or the closest ancestor element that has the attribute with the specified value, or null if no matching element is found.
 * 
 * Example usage:
 * <div data-gtm-type="resource_card">
 *   <button>Click Me</button>
 * </div>
 * 
 * // Assuming the button was clicked and event.target is the button element
 * getClosestAttributeWithValue({
 *   target: event.target,
 *   attribute: 'data-gtm-type',
 *   value: 'resource_card'
 * }) => the div element with data-gtm-type="resource_card"
 */
export default function getClosestAttributeWithValue({ target, attribute, value }) {
    // Check if the target itself has the attribute
    if (target.getAttribute(attribute) == value) {
        return target;
    }
    // Otherwise, use closest to find the nearest ancestor with the matching attribute and value
    return target.closest(`[${attribute}="${value}"]`);
}
