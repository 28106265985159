import headerActionButtons from "./actionButtonTypes/headerActionButtons";
import footerActionButtons from "./actionButtonTypes/footerActionButtons";

/**
 * Listen to all action button clicks on the site and decide if we should pass into GTM
 */

const getActionButtonTarget = target => target.hasAttribute("data-gtm-click") ? target : target.closest("[data-gtm-click]");

/**
 * This is a list of modules to test what kind of link this is and what event 
 * should be sent to GTM based on the dataLayer rules
 * These are performed in the order they are shown in the list and will only fire
 * a single event.
 * 
 * Each module exports an object with the following shape
 * 
 * {
 *   test: function(){}, Takes the ops object and tests the link or target of the click event to evaluate if this should trigger the GTM event. Return Boolean
 *   push: function(){}, Does the actual work of calling trackEvent() and creating the object shape to push
 *   name: String used to debug :) 
 * }
 */
const actionButtonTestTypes = [headerActionButtons, footerActionButtons];

const trackLink = (ops) => {
  for (i = 0; i < actionButtonTestTypes.length; i++) {
    const test = actionButtonTestTypes[i].test;

    if (test(ops)) {
      const push = actionButtonTestTypes[i].push;
      push(ops);
      break;
    }
  }
};

/**
 * Capture all clicks on the body and ensure the clicked item
 * has the href value with a value
 */
document.body.addEventListener("click", (e) => {
    const target = getActionButtonTarget(e.target);

    if (target && target.localName !== "a") {
        // The `target` here should be the HTML element.
        trackLink({ target, e });
    }
});