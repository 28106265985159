/**
 * 
 * @param {DOM Element} element 
 * @returns the value of the closest element with the attribute of data-gtm-section if found. Otherwise empty string.
 * 
 * <div data-gtm-section="articles">
 *  <a class="articleLink" href="">Article link</a>
 * </div>
 * 
 * getSectionElement(document.querySelector(".articleLink")) => "articles"
 */

const getSectionElement = (element) => element.closest("[data-gtm-section]");

export default (element) => {
  const el = getSectionElement(element);
  return el ? el.dataset.gtmSection : "";
};