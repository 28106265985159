import breakpointIs from "../../modules/breakpointIs";
export const scrollTo = (scrollToElement, ops = {}) => {
  const navigationHeight = document.querySelector(".navigation")?.offsetHeight || 0;
  let pageMenuHeight;
  
  if (breakpointIs("lg")) {
    pageMenuHeight = document.querySelector(".pageMenu")?.offsetHeight || 0;
  } else {
    const pageMenuAccordionTriggerHeight = document.querySelector(".pageMenu .accordion__trigger")?.offsetHeight || 0;
    const pageMenuAccordionContentHeight = document.querySelector(".pageMenu .accordion__content")?.offsetHeight ||
    0;
    pageMenuHeight = pageMenuAccordionTriggerHeight + pageMenuAccordionContentHeight;
  }
  const totalStickyHeight = navigationHeight + pageMenuHeight;
  const scrollToElementPos = scrollToElement.getBoundingClientRect().top;
  const elementTopPos =
    scrollToElementPos - document.body.getBoundingClientRect().top;
  const topPos = elementTopPos - totalStickyHeight;

  window.scrollTo({
    top: topPos,
    behavior: "smooth",
    ...ops,
  });
};
