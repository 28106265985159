/**
 * Tooltip
 * Looks in all rich text sections and binds tippy plugin to the tooltip class.
 * TODO: This along with responsive tables should be refactored to live in UIKit
 * and only load when rich text is used.
 */

const TOOLTIP_SELECTOR = ".rich-text .tooltip";
const TOOLTIP_CONTENT_SELECTOR = ".tooltip-content";
const TOOLTIP_TRIGGER_CLASS_LIST = ["underline", "font-bold", "cursor-pointer"];


const tooltips = document.querySelectorAll(TOOLTIP_SELECTOR);
const hasTooltips = () => tooltips.length > 0;

const bindTippy = ({ el, tippy }) => {
  // Define DOM elements
  const trigger = el;
  const content = el.querySelector(TOOLTIP_CONTENT_SELECTOR);

  // Bind decodation classes to trigger
  trigger.classList.add(...TOOLTIP_TRIGGER_CLASS_LIST);

  // Enable focus on the trigger
  trigger.setAttribute("tabindex", "0");
  trigger.setAttribute("role", "button");

  // Init tippy
  const tippyInstance = tippy(trigger, {
    content: content,
    allowHTML: true,
    theme: "custom",
    trigger: "mouseenter click",
  });

  trigger.addEventListener("keydown", (e) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      // Toggle the tooltip visibility
      if (tippyInstance.state.isShown) {
          tippyInstance.hide(); // Hide if already shown
      } else {
          tippyInstance.show(); // Show if not shown
      }
    }
  });

  // Hide tooltip when focus out
  trigger.addEventListener("focusout", () => {
      tippyInstance.hide();
  });
};

const initTooltips = () => {
  import("tippy.js").then(({ default: tippy }) => {
    tooltips.forEach((el) => {
      bindTippy({ el, tippy });
    });
  });
};

if (hasTooltips()) {
  initTooltips();
}
