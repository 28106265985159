import { URL_CPC_PROFILE } from "../../../const/api";
import siteConfig from "../../../modules/siteConfig";
import goToURL from "../../../modules/goToURL";

const requestProfilePage = async ({ action, userid }) => {
  const postResp = await fetch(action, {
    headers: {
      "Content-Type": "application/json",
      "x-sny-siteoverride": siteConfig.site,
    },
    method: "post",
    body: JSON.stringify({
      userid: userid,
      returnto: window.location.href,
      requestedLocale: siteConfig.currentLocale
    }),
  }).then((response) => response.json());

  return postResp;
};

export default async () => {
  const profileResp = await requestProfilePage({
    action: `${siteConfig.context}${URL_CPC_PROFILE}`,
    siteoverride: siteConfig.site,
    userid: siteConfig.userid
  });

  if (profileResp.isSuccess) {
    const profileLink = profileResp?.entity?.profilelink;
    goToURL(profileLink);
  } else {
    console.errorGroup("Unable to load profile page link");
    console.log(profileResp);
    console.groupEnd();
  }
};