import Alpine from "alpinejs";
import goToUserProfile from "./modules/goToUserProfile";

Alpine.magic('auth', (el, {Alpine}) => {
  return (action, config) => {
    switch(action){
      case "goToUserProfile":
        goToUserProfile(config);
        break;
      default:
        console.warn('Auth requires an action.');
    }
  };
})

