import Alpine from "alpinejs";

Alpine.magic("getCookie", () => async (name) => {
  const { default: api } = await import(
    "../../../../node_modules/js-cookie/dist/js.cookie.mjs"
  );
  return api.get(name);
});

Alpine.magic("setCookie", () => async (name, value, options = {}) => {
  const { default: api } = await import(
    "../../../../node_modules/js-cookie/dist/js.cookie.mjs"
  );
  return api.set(name, value, (options = {}));
});

Alpine.magic("removeCookie", () => async (name, options = {}) => {
  const { default: api } = await import(
    "../../../../node_modules/js-cookie/dist/js.cookie.mjs"
  );
  return api.remove(name, options);
});